import React, { FC } from 'react';
import { IconButton, IconButtonSkins } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { FieldPrivacy } from '../../../../../types';
import { Popover } from '../Popover';
import { LockIcon, PublicIcon } from '../icons';
import { classes } from './PrivacyIcon.st.css';

type Props = {
  privacy: FieldPrivacy;
  onClick?(): void;
  disabled?: boolean;
  'data-hook'?: string;
  'aria-label'?: string;
};

const tooltipText = {
  [FieldPrivacy.PRIVATE]: 'app.widget.fields.private',
  [FieldPrivacy.PUBLIC]: 'app.widget.fields.public',
  [FieldPrivacy.UNKNOWN]: 'app.widget.fields.public',
};

const tooltipIcon = {
  [FieldPrivacy.PRIVATE]: <LockIcon />,
  [FieldPrivacy.PUBLIC]: <PublicIcon />,
  [FieldPrivacy.UNKNOWN]: <PublicIcon />,
};

export const PrivacyIcon: FC<Props> = ({
  privacy,
  onClick,
  disabled = false,
  'data-hook': dataHook,
  'aria-label': ariaLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      popoverContent={t(tooltipText[privacy])}
      className={classes.popover}
    >
      <IconButton
        className={classes.iconButton}
        skin={IconButtonSkins.Full}
        icon={tooltipIcon[privacy]}
        onClick={onClick}
        disabled={disabled}
        data-hook={dataHook}
        aria-haspopup
        aria-label={ariaLabel}
      />
    </Popover>
  );
};
