import React, { FC } from 'react';
import { Section } from '@wix/ambassador-members-v1-custom-field/types';

import { useFormFields } from '../../../../../contexts/form';
import { FieldGroup } from '../../FieldGroup';
import { classes } from './AddressSection.st.css';

export const AddressSection: FC = () => {
  const fields = useFormFields();

  return (
    <div className={classes.root}>
      <FieldGroup fields={fields} section={Section.ADDRESS} />
    </div>
  );
};
