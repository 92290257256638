import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  SectionNotification,
  SectionNotificationType,
} from 'wix-ui-tpa/cssVars';
import { Formik, Field } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Alert } from '@wix/wix-ui-icons-common/on-stage';

import { ChangeEmailValues, ModalType } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { LoginInfoTextField } from '../../common/LoginInfoTextField';
import { initialValues } from './constants';
import { useChangeEmailValidation } from './useChangeEmailValidation';
import { LoginInfoDialog } from '../../common/LoginInfoDialog/LoginInfoDialog';
import { LoginInfoTextButton } from '../../common/LoginInfoTextButton';
import {
  useLoginInfoActions,
  useUiActions,
  useUiState,
} from '../../../../../contexts/widget';
import { stageToButtonState } from '../../common/LoginInfoDialog';

export const ChangeLoginEmailModal: FC<ModalType> = ({ onClose }) => {
  const { t } = useTranslation();
  const validate = useChangeEmailValidation();
  const { changeEmailState } = useUiState();
  const { resetLoginInfoState } = useUiActions();
  const { recoverPassword, updateLoginEmail } = useLoginInfoActions();

  const initialErrors = useMemo(
    () =>
      changeEmailState.stage === 'fail' ? changeEmailState.errors : undefined,
    [changeEmailState],
  );

  const changeEmail = useCallback(
    ({ newEmail, password }: ChangeEmailValues) => {
      updateLoginEmail({ newEmail, password });
    },
    [updateLoginEmail],
  );

  const forgotPassword = useCallback(
    () => recoverPassword(),
    [recoverPassword],
  );

  const closeDialog = useCallback(() => {
    onClose();
    resetLoginInfoState();
  }, [onClose, resetLoginInfoState]);

  useEffect(() => {
    switch (changeEmailState.stage) {
      case 'ok':
        closeDialog();
        break;
      case 'fail':
        if (!changeEmailState.errors) {
          const newEmail = document.forms
            .namedItem('changeEmail')
            ?.elements.namedItem('newEmail');
          if (newEmail instanceof HTMLInputElement) {
            newEmail.focus();
          }
        }
        break;
    }
  }, [changeEmailState.stage]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={changeEmail}
      validate={validate}
    >
      {({ submitForm }) => (
        <LoginInfoDialog
          name="changeEmail"
          state={stageToButtonState(changeEmailState.stage)}
          isOpen
          data-hook={DataHook.ChangeEmailModal}
          title={t('app.widget.modals.change-email.title')}
          subtitle={t('app.widget.modals.change-email.subtitle')}
          onClose={closeDialog}
          primaryText={t('app.widget.modals.change-email.change-button')}
          primaryOnClick={submitForm}
          secondaryText={t('app.widget.modals.change-email.cancel-button')}
        >
          <SectionNotification type={SectionNotificationType.alert}>
            <SectionNotification.Icon
              icon={<Alert color="#FFB600" size={24} />}
            />
            <SectionNotification.Text>
              {t('app.widget.modals.change-email.warning')}
            </SectionNotification.Text>
          </SectionNotification>

          <Field
            as={LoginInfoTextField}
            autoFocus
            name="newEmail"
            type="email"
            label={t('app.widget.modals.change-email.new-email.label')}
            data-hook={DataHook.ChangeEmailNewInput}
          />

          <Field
            as={LoginInfoTextField}
            name="confirmEmail"
            type="email"
            label={t('app.widget.modals.change-email.confirm-email.label')}
            data-hook={DataHook.ChangeEmailConfirmInput}
          />

          <Field
            as={LoginInfoTextField}
            type="password"
            name="password"
            label={t('app.widget.modals.change-email.password.label')}
            data-hook={DataHook.ChangeEmailPasswordInput}
            bottom={
              <div>
                <LoginInfoTextButton
                  onClick={forgotPassword}
                  data-hook={DataHook.ChangeEmailForgotPassword}
                >
                  {t('app.widget.modals.change-email.password.action')}
                </LoginInfoTextButton>
              </div>
            }
          />
        </LoginInfoDialog>
      )}
    </Formik>
  );
};
