import React, { FC } from 'react';
import { TextButton, TextButtonProps } from 'wix-ui-tpa/cssVars';

import { classes, st } from './LoginInfoTextButton.st.css';

export const LoginInfoTextButton: FC<TextButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <TextButton className={st(classes.monochrome, className)} {...props} />
  );
};
