import React, { type FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../../constants/DataHook';
import { classes, st } from './BlockedMembersModalEmptyState.st.css';

const BlockedMembersModalEmptyState: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.emptyState, { mobile: isMobile })}>
      <Text
        data-hook={DataHook.BlockedMembersModalEmptyStateText}
        className={classes.emptyStateText}
        tagName="p"
      >
        {t('app.widget.modals.blocked-members.empty-state')}
      </Text>
    </div>
  );
};

export default BlockedMembersModalEmptyState;
