import { useFormikContext } from 'formik';

import type { Field, Member } from '../types';

interface FormValues {
  fields: Field[];
  member: Member;
}

export const useFormFields = () => {
  const context = useFormikContext<FormValues>();

  if (!context) {
    throw new Error('Form context is missing');
  }

  return context.values?.fields ?? [];
};
