import React, { FC } from 'react';
import { classes } from './Loading.st.css';

export const LoadingIcon: FC<React.PropsWithChildren<unknown>> = () => (
  <div className={classes.container}>
    <svg className={classes.spinner} viewBox="0 0 16 16">
      <circle
        className={classes.path}
        cx="8"
        cy="8"
        r="7"
        fill="none"
        stroke-width="1"
      ></circle>
    </svg>
  </div>
);
