import { Form as FormikForm, useFormikContext } from 'formik';
import React, { FC } from 'react';

import { useBeforeUnload } from '../../../../hooks';
import { useErrorFocus } from './useErrorFocus';

export const Form: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { dirty, errors, isSubmitting, isValidating } = useFormikContext();

  useBeforeUnload(dirty);
  useErrorFocus(isSubmitting, isValidating, errors);

  return <FormikForm noValidate={true}>{children}</FormikForm>;
};
