import React, { FC, FocusEventHandler, useRef, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useField as useFormikField } from 'formik';
import { TextField, TextFieldTheme } from 'wix-ui-tpa/cssVars';

import { isTitleField } from '../../../../../../server/services/reservedFields';
import { DataHook } from '../../../../../../constants/DataHook';
import { Field, FieldWrapper } from '../../../../../../types';
import {
  useFieldLabel,
  useFieldPrivacyAriaLabel,
} from '../../../../../../hooks';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { classes, st } from './Text.st.css';

type ErrorType = { value: string } | undefined;

interface TextProps extends FieldWrapper {
  pattern?: HTMLInputElement['pattern'];
  type?: HTMLInputElement['type'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Text: FC<TextProps> = ({
  formikFieldSelector,
  theme,
  type,
  pattern,
  onBlur,
}) => {
  const { t } = useTranslation();
  const [formikField, meta, helpers] = useFormikField<Field | null>(
    formikFieldSelector,
  );
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const fieldContainerRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<TextField>(null);
  const { getFieldLabel } = useFieldLabel();
  const { getFieldPrivacyAriaLabel } = useFieldPrivacyAriaLabel();

  const field = formikField.value;
  const error = (meta.error as unknown as ErrorType)?.value;
  const isFieldThemeBox = theme === TextFieldTheme.Box;

  if (!field) {
    return null;
  }

  const { required, infoTooltipText, value, key, id } = field;

  return (
    <div className={st(classes.root)} data-hook={key} ref={fieldContainerRef}>
      <FieldLabel
        text={getFieldLabel(field)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
        aria-label={getFieldPrivacyAriaLabel(field)}
      >
        <TextField
          id={id}
          className={st(classes.field, { box: isFieldThemeBox })}
          data-hook={DataHook.TextField}
          maxLength={isTitleField(field) ? undefined : 255}
          value={
            value ??
            '' /* Need to pass empty string, for formik form reset to work*/
          }
          theme={theme}
          ref={fieldRef}
          type={type ?? 'text'}
          required={required}
          pattern={pattern}
          errorMessage={error}
          withClearButton={isClearButtonVisible}
          clearButtonAriaLabel={t('app.widget.fields.clear-button.aria-label')}
          error={!!error}
          onClear={() => {
            helpers.setValue({ ...field, value: '' });
            fieldRef.current?.focus();
          }}
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: event.target.value,
            });
            setClearButtonVisibility(!!event.target?.value);
          }}
          onFocus={() => setClearButtonVisibility(!!value)}
          onBlur={async (event) => {
            // https://deepscan.io/docs/rules/react-missing-event-persist
            event.persist();

            const isClearButtonFocused = fieldContainerRef.current?.contains(
              event.relatedTarget,
            );
            if (!isClearButtonFocused) {
              setTimeout(() => setClearButtonVisibility(false), 100);
              await onBlur?.(event);
              formikField.onBlur(event);
            }
          }}
          errorTooltipMaxWidth={2000}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
