import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { FieldSection } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { useFormFields } from '../../../../../contexts/form';
import settingsParams from '../../../settingsParams';
import { SectionHeader } from '../../common/SectionHeader';
import { AvatarInput } from '../../common/AvatarInput';
import { FieldGroup } from '../../FieldGroup';
import { classes, st } from './PublicInfo.st.css';

type Settings = ReturnType<typeof useSettings>;

const MEMBER_FORMIK_FIELD_SELECTOR = 'member';

const getIsAvatarVisible = (settings: Settings) => {
  return settings.get(settingsParams.avatarVisibility) ?? false;
};

export const PublicInfo: FC = () => {
  const fields = useFormFields();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const isAvatarVisible = getIsAvatarVisible(settings);

  return (
    <div className={classes.root} data-hook={DataHook.DisplayInfoSection}>
      <SectionHeader
        title={settings.get(settingsParams.publicInfoSectionTitle)}
        subtitle={settings.get(settingsParams.publicInfoSectionSubtitle)}
      />
      <div
        className={st(classes.fieldGroup, {
          withAvatar: isAvatarVisible,
          mobile: isMobile,
        })}
      >
        <FieldGroup
          fields={fields}
          section={FieldSection.DISPLAY_INFO}
          isSingleColumn={isAvatarVisible}
        />
        {isAvatarVisible && (
          <AvatarInput formikFieldSelector={MEMBER_FORMIK_FIELD_SELECTOR} />
        )}
      </div>
    </div>
  );
};
