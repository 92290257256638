import { useContext } from 'react';
import { WidgetContext } from './Provider';

export const useFields = () => useContext(WidgetContext).fields;

export const useCountryDropdownOptions = () =>
  useContext(WidgetContext).locale.countriesData;

export const useAccountDataActions = () =>
  useContext(WidgetContext).accountDataHandlers;

export const useMember = () => useContext(WidgetContext).member;

export const useMemberActions = () => useContext(WidgetContext).memberHandlers;

export const useSiteContext = () => useContext(WidgetContext).site;

export const useUiActions = () => useContext(WidgetContext).uiHandlers;

export const useUiState = () => useContext(WidgetContext).ui;

export const useAppSettingsState = () => useContext(WidgetContext).appSettings;

export const useLoginInfoActions = () =>
  useContext(WidgetContext).loginInfoHandlers;
