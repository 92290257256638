import { MemberRole, ProfilePrivacyStatus } from '../../../../../types';

const getIsPublicProfile = (privacyStatus: ProfilePrivacyStatus) => {
  return privacyStatus === ProfilePrivacyStatus.PUBLIC;
};

export const getIsSiteOwner = (role: MemberRole | undefined) =>
  role === MemberRole.OWNER;

export const shouldShowCommunityUrlItem = (
  isSocial: boolean,
  privacyStatus: ProfilePrivacyStatus,
) => isSocial && getIsPublicProfile(privacyStatus);

export const shouldShowProfileVisibilityItem = (role: MemberRole | undefined) =>
  !getIsSiteOwner(role);

export const shouldShowBlockedMembersItem = (role: MemberRole | undefined) =>
  !getIsSiteOwner(role);
