import { useExperiments } from '@wix/yoshi-flow-editor';

import { useMember } from '../../contexts/widget';
import { Experiment } from '../../types';
import { shouldShowProfileVisibilityItem } from '../../components/MyAccount/Widget/Sections/AccountSettings';

export const useShowProfileVisibilityItem = () => {
  const { experiments } = useExperiments();
  const { role } = useMember();

  const showForAllUsers = experiments.enabled(
    Experiment.ShowPrivacySettingsMessageForSiteOwners,
  );

  return showForAllUsers || shouldShowProfileVisibilityItem(role);
};
