import { createEventHandler } from '@wix/tpa-settings';

import { SettingsEvent } from './appSettings';

export enum PageAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type SettingsEventHandler = ReturnType<
  typeof createEventHandler<SettingsEvent>
>;
