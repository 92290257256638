import { StatesButtonStates } from 'wix-ui-tpa/cssVars';

import { ChangeLoginInfoState } from '../../../../../types';

export function stageToButtonState<T>(stage: ChangeLoginInfoState<T>['stage']) {
  switch (stage) {
    case 'pending':
      return StatesButtonStates.IN_PROGRESS;
    default:
      return StatesButtonStates.IDLE;
  }
}
