import { useEffect } from 'react';

import { useModal } from '../../../../../contexts/modals/hooks';
import { useUiState } from '../../../../../contexts/widget';
import { BlockedMembersModal } from '../../modals/BlockedMembersModal';
import { MemberRole } from '../../../../../types';
import { shouldShowBlockedMembersItem } from './helpers';

const useOpenBlockedModalOnce = (
  blockedMembersCount: number,
  role?: MemberRole,
) => {
  const { shouldOpenBlockedModal } = useUiState();
  const { showModal } = useModal();

  const canOpenBlockedModal =
    shouldShowBlockedMembersItem(role) && blockedMembersCount > 0;

  useEffect(() => {
    if (shouldOpenBlockedModal && canOpenBlockedModal) {
      showModal(BlockedMembersModal);
    }
  }, [showModal, shouldOpenBlockedModal, canOpenBlockedModal]);
};

export { useOpenBlockedModalOnce };
