import { Field, PublicInfoFieldKey } from '../../../../types';

const trimValue = (field: Field) => ({
  ...field,
  value: field.value ? field.value.trim() : field.value,
});

const fieldSanitizerMap: Record<string, (field: Field) => Field> = {
  [PublicInfoFieldKey.TITLE]: trimValue,
  [PublicInfoFieldKey.DISPLAY_NAME]: trimValue,
};

export const getFieldsWithSanitizedValues = (fields: Field[]) => {
  return fields.map((field) => {
    const fieldSanitizer = fieldSanitizerMap[field.key];
    return fieldSanitizer ? fieldSanitizer(field) : field;
  });
};
