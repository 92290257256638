import { ToastSkin } from 'wix-ui-tpa/cssVars';
import { Experiments, TFunction } from '@wix/yoshi-flow-editor';

import { Toast, ToastConfig } from '../../../../types';
import { Experiment } from '../../../../types/experiments';

type GetToastContentProps = {
  t: TFunction;
  toastConfig: ToastConfig;
  experiments: Experiments;
};

export const getToastContent = ({
  t,
  experiments,
  toastConfig,
}: GetToastContentProps) => {
  let toastSkin;
  let toastContent: string | null;

  switch (toastConfig.type) {
    case Toast.FORM_UPDATE_SUCCESS:
      toastContent = t('app.widget.toast.save-success');
      toastSkin = ToastSkin.success;
      break;
    case Toast.FORM_UPDATE_ERROR_INTERNAL:
      toastContent = t('app.widget.toast.save-error-internal');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNAUTHENTICATED:
      toastContent = t('app.widget.toast.save-error-unauthenticated');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNKNOWN:
      toastContent = t('app.widget.toast.save-error-unknown');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_INVALID_ARGUMENT:
      toastContent = t('app.widget.toast.save-error-invalid-argument');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNAVAILABLE:
      toastContent = t('app.widget.toast.save-error-unavailable');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_PERMISSION_DENIED:
      toastContent = t('app.widget.toast.save-error-permission-denied');
      toastSkin = ToastSkin.error;
      break;
    case Toast.FORM_UPDATE_ERROR_INVALID_URL:
      toastContent = t('app.widget.toast.save-error-invalid-url');
      toastSkin = ToastSkin.error;
      break;
    case Toast.PROFILE_URL_UPDATE_SUCCESS:
      toastContent = t('app.widget.toast.profile-url-update-success');
      toastSkin = ToastSkin.success;
      break;
    case Toast.PROFILE_URL_UPDATE_ERROR:
      toastContent = t('app.widget.toast.profile-url-update-error');
      toastSkin = ToastSkin.error;
      break;
    case Toast.PROFILE_PRIVACY_CHANGE_SUCCESS:
      toastContent = t('app.widget.toast.profile-privacy-update-success');
      toastSkin = ToastSkin.success;
      break;
    case Toast.PROFILE_PRIVACY_CHANGE_ERROR:
      toastContent = t('app.widget.toast.profile-privacy-update-error');
      toastSkin = ToastSkin.error;
      break;
    case Toast.UNBLOCK_MEMBER_SUCCESS: {
      toastContent = t(
        'app.widget.blocked-members.unblocked-dynamic-member-name',
        toastConfig.options,
      );
      toastSkin = ToastSkin.success;

      break;
    }
    case Toast.UNBLOCK_MEMBER_ERROR:
      toastContent = t('app.widget.blocked-members.unblock-error');
      toastSkin = ToastSkin.error;
      break;

    case Toast.LOGIN_INFO_EMAIL_CHANGE_SUCCESS:
      toastContent = t('app.widget.sections.login-info.toast-email-changed');
      toastSkin = ToastSkin.success;
      break;
    case Toast.LOGIN_INFO_PASSWORD_CHANGE_SUCCESS:
      toastContent = t('app.widget.sections.login-info.toast-password-changed');
      toastSkin = ToastSkin.success;
      break;
    case Toast.LOGIN_INFO_TECHNICAL_ERROR:
      toastContent = t('app.widget.sections.login-info.toast-generic-error');
      toastSkin = ToastSkin.error;
      break;
    case Toast.LOGIN_INFO_RESET_PASSWORD_SUCCESS:
      toastContent = t('app.widget.sections.login-info.toast-reset-password');
      toastSkin = ToastSkin.success;
      break;

    default:
      toastContent = null;
      toastSkin = ToastSkin.success;
  }

  return {
    toastContent,
    toastSkin,
  };
};
