import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Formik } from 'formik';
import { debounce } from 'lodash';

import { getValidationSchema } from './validationSchema';
import { Form } from './Form';
import {
  useFields,
  useMember,
  useAccountDataActions,
} from '../../../../contexts/widget';
import { useBi } from '../../../../hooks';
import { Field, Member } from '../../../../types';
import { getFieldsWithChangedPrivacy, getChangedFields } from './changedFields';
import { getFieldsWithSanitizedValues } from './sanitizeFields';

const SUBMIT_DEBOUNCE_TIMEOUT = 1000;

export const AccountForm: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const biLogger = useBi();
  const fields = useFields();
  const member = useMember();
  const [validateOnBlur, setValidationOnBlur] = useState(false);
  const { updateAccountData } = useAccountDataActions();
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const initialValues = useMemo(() => ({ fields, member }), [fields, member]);

  const onSubmit = useMemo(
    () =>
      debounce(
        (
          currentFields: Field[],
          initialFields: Field[],
          currentMember: Member,
          bi: ReturnType<typeof useBi>,
        ) => {
          const sanitizedFields = getFieldsWithSanitizedValues(currentFields);
          const changedFields = getChangedFields(currentFields, initialFields);
          const changedPrivacyFields = getFieldsWithChangedPrivacy(
            currentFields,
            initialFields,
          );

          updateAccountData({
            currentFields: sanitizedFields,
            changedPrivacyFields,
            currentMember,
          });

          bi.profileEdited({ changedFields, changedPrivacyFields });
        },
        SUBMIT_DEBOUNCE_TIMEOUT,
        { leading: true },
      ),
    [updateAccountData],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ fields: currentFields, member: currentMember }) =>
        onSubmit(currentFields, fields, currentMember, biLogger)
      }
      validate={() => {
        setValidationOnBlur(true);
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={validateOnBlur}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  );
};
