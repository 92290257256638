import * as yup from 'yup';
import { ChangeEmailValues } from '../../../../../types';

export const initialValues: ChangeEmailValues = {
  newEmail: '',
  confirmEmail: '',
  password: '',
};

export const emailSchema = yup.string().email();
