import React, { FC } from 'react';
import { classes } from './Lock.st.css';

export const LockIcon: FC<React.PropsWithChildren<unknown>> = () => (
  <svg className={classes.svg} width="14px" height="16px" viewBox="0 0 14 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-5.000000, -4.000000)" fill="#000000">
        <path d="M12,4 C14.6887547,4 16.8818181,6.12230671 16.9953805,8.78311038 L17,9 L17,11 L19,11 L19,20 L5,20 L5,11 L7,11 L7,9 C7,6.23857625 9.23857625,4 12,4 Z M12,5 C9.85780461,5 8.10892112,6.68396847 8.00489531,8.80035966 L8,9 L8,11 L16,11 L16,9 C16,6.790861 14.209139,5 12,5 Z" />
      </g>
    </g>
  </svg>
);
