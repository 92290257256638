import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';

import { Field, FieldOrigin, FieldPrivacy } from '../../types';

const hasTranslation = (t: TFunction, key: string) => t(key) !== key;

const getPostfix = (index?: number) => (index ? index + 1 : null);
const appendPostfix = (label: string, postfix: number | null) => {
  return postfix ? `${label} ${postfix}` : label;
};

export const useFieldLabel = () => {
  const { t } = useTranslation();

  const getFieldLabel = (field: Field) => {
    const { key, label, index, fieldOrigin } = field;
    const postfix = getPostfix(index);

    if (fieldOrigin !== FieldOrigin.CUSTOM && hasTranslation(t, key)) {
      return appendPostfix(t(key), postfix);
    } else {
      return appendPostfix(label, postfix);
    }
  };

  return {
    getFieldLabel,
  };
};

export const useFieldPrivacyAriaLabel = () => {
  const { t } = useTranslation();
  const { getFieldLabel } = useFieldLabel();

  const getFieldPrivacyAriaLabel = (field: Field) => {
    const fieldLabel = getFieldLabel(field);
    const isPrivate = field.privacy === FieldPrivacy.PRIVATE;

    if (isPrivate) {
      return t('app.widget.fields.privacy-button.aria-label.private', {
        fieldLabel,
      });
    }

    return t('app.widget.fields.privacy-button.aria-label.public', {
      fieldLabel,
    });
  };

  return {
    getFieldPrivacyAriaLabel,
  };
};
