import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Button,
  ButtonPriority,
  StatesButton,
  StatesButtonStates,
  InputDialog,
  InputDialogProps,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../constants/DataHook';
import { classes, st } from './LoginInfoDialog.st.css';

export interface LoginInfoDialogProps
  extends Omit<InputDialogProps, 'primaryButton'> {
  name?: string;
  state: StatesButtonStates.IDLE | StatesButtonStates.IN_PROGRESS;
  primaryText: string;
  primaryOnClick(): void;
  secondaryText: string;
}

export const LoginInfoDialog: FC<PropsWithChildren<LoginInfoDialogProps>> = ({
  name,
  children,
  primaryText,
  primaryOnClick,
  secondaryText,
  state,
  ...props
}) => {
  const form = useRef<HTMLFormElement>(null);
  const lastSubmitCount = useRef(0);
  const { isMobile } = useEnvironment();
  const { submitCount, isValid } = useFormikContext();

  useEffect(() => {
    if (!isValid && lastSubmitCount.current < submitCount) {
      form.current
        ?.querySelector<HTMLInputElement>('input[aria-invalid="true"]')
        ?.focus();
      lastSubmitCount.current = submitCount;
    }
  }, [submitCount, isValid]);

  return (
    <InputDialog
      {...props}
      forceBWTheme
      withoutDivider
      className={st(classes.dialog, { mobile: isMobile })}
      customFooter={
        <div className={classes.footer}>
          <Button
            upgrade
            className={classes.secondary}
            data-hook={DataHook.LoginInfoDialogSecondaryButton}
            priority={ButtonPriority.basicSecondary}
            onClick={props.onClose}
          >
            {secondaryText}
          </Button>
          <StatesButton
            data-hook={DataHook.LoginInfoDialogPrimaryButton}
            state={state}
            upgrade
            className={classes.primary}
            onClick={primaryOnClick}
            idleContent={primaryText}
            inProgressContent={
              <ThreeDotsLoader
                className={classes.loader}
                data-hook={DataHook.LoginInfoDialogLoader}
              />
            }
          />
        </div>
      }
    >
      <Form ref={form} className={classes.wrapper} name={name}>
        {children}
      </Form>
    </InputDialog>
  );
};
