type GetShouldShowPrivacyIconOptions = {
  index: number | undefined;
  isPublicProfile: boolean;
  isPrivacyEditable: boolean;
};

export const getShouldShowPrivacyIcon = ({
  index,
  isPublicProfile,
  isPrivacyEditable,
}: GetShouldShowPrivacyIconOptions) => {
  return isPublicProfile && isPrivacyEditable && !index;
};
