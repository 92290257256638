import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { FieldSection } from '../../../../../types';
import { useFormFields } from '../../../../../contexts/form';
import settingsParams from '../../../settingsParams';
import { SectionHeader } from '../../common/SectionHeader';
import { FieldGroup } from '../../FieldGroup';
import { classes } from './SocialLinks.st.css';

export const SocialLinks: FC = () => {
  const fields = useFormFields();
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <SectionHeader title={settings.get(settingsParams.socialSectionTitle)} />
      <FieldGroup fields={fields} section={FieldSection.SOCIAL} />
    </div>
  );
};
