import React from 'react';
import {
  TextButtonPriority,
  TextButton,
  Text,
  Accordion,
} from 'wix-ui-tpa/cssVars';
import { TFunction } from '@wix/yoshi-flow-editor';

import { DataHook } from '../../../../../../../constants/DataHook';
import { ProfilePrivacyStatus, BiActionType } from '../../../../../../../types';
import { useBi } from '../../../../../../../hooks/useBi';
import { ModalContextType } from '../../../../../../../contexts/modals/Provider';
import { ProfilePrivacyModal } from '../../../../modals';
import { classes } from './ProfileVisibility.st.css';

const PROFILE_VISIBILITY_ITEM_ID = 'profileVisibility';

type Props = {
  t: TFunction;
  showModal: ModalContextType['showModal'];
  privacyStatus: ProfilePrivacyStatus;
  biLogger: ReturnType<typeof useBi>;
  isSiteOwner: boolean;
};

export const getProfileVisibilityItem = ({
  t,
  showModal,
  privacyStatus,
  biLogger,
  isSiteOwner,
}: Props) => {
  const isProfilePublic = privacyStatus === ProfilePrivacyStatus.PUBLIC;

  const subtitle = isSiteOwner
    ? t('app.widget.change-privacy.info-for-site-owner')
    : undefined;

  const ctaText = isProfilePublic
    ? t('app.widget.change-privacy.make-private-cta')
    : t('app.widget.change-privacy.make-public-cta');

  const onChangeProfilePrivacyClick = () => {
    if (isProfilePublic) {
      biLogger.leaveCommunityAttempt();
    } else {
      biLogger.joinCommunityAttempt();
    }
    showModal(ProfilePrivacyModal);
  };

  const onAccordionItemClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Visibility_Settings);
  };

  return (
    <Accordion.Item
      id={PROFILE_VISIBILITY_ITEM_ID}
      title={t('app.widget.change-privacy.title')}
      subtitle={subtitle}
      onClick={onAccordionItemClick}
      disabled={isSiteOwner}
      data-hook={DataHook.VisibilityAccordionItem}
    >
      {!isSiteOwner && (
        <>
          <Text className={classes.content} tagName="div">
            {t<string>('app.widget.change-privacy.subtitle')}
          </Text>
          <TextButton
            className={classes.cta}
            priority={TextButtonPriority.link}
            onClick={onChangeProfilePrivacyClick}
            data-hook={DataHook.ChangeProfilePrivacyButton}
          >
            {ctaText}
          </TextButton>
        </>
      )}
    </Accordion.Item>
  );
};
