export enum DataHook {
  WidgetWrapper = 'MyAccount-wrapper',
  SectionTitle = 'MyAccount-sectionTitle',
  EditProfileUrlModal = 'MyAccount-changeProfileUrlModal',
  DiscardChangesButton = 'MyAccount-discardChangesButton',
  DiscardChangesModal = 'MyAccount-discardChangesModal',
  BlockedMemberAccordionItem = 'BlockedMemberAccordionItem',
  ProfileUrlAccordionItem = 'MyAccount-profileUrlAccordionItem',
  VisibilityAccordionItem = 'MyAccount-visibilityAccordionItem',
  BlockedMembersSeeAllButton = 'MyAccount-memberBlockMemberSeeAllButton',
  BlockedMembersModal = 'MyAccount-memberBlockMemberModal',
  BlockedMembersModalEmptyStateText = 'MyAccount-memberBlockMemberModal-emptyStateText',
  BlockedMemberModal = 'tpa-modal-content',
  EditProfileUrlButton = 'MyAccount-editProfileUrlButton',

  ProfilePrivacyModal = 'MyAccount-profilePrivacyModal',
  ProfilePrivacyModalConfirmButton = 'MyAccount-profilePrivacyModal-confirmButton',
  ProfilePrivacyModalCancelButton = 'MyAccount-profilePrivacyModal-cancelButton',
  ProfilePrivacyModalTitle = 'MyAccount-profilePrivacyModal-title',

  ChangeProfilePrivacyButton = 'MyAccount-changeProfilePrivacyButton',
  FieldPrivacyModal = 'MyAccount-fieldPrivacyModal',
  FieldPrivacyIcon = 'MyAccount-fieldPrivacyIcon',
  TextField = 'MyAccount-textField',
  EditProfileUrlSlugInput = 'MyAccount-editProfileUrlSlugInput',
  AddressFieldPrivacyIcon = 'MyAccount-addressFieldPrivacyIcon',
  Settings = 'MyAccountSettings-settings',
  MainTab = 'MyAccountSettings-mainTab',
  MainTabContainer = 'MyAccountSettings-mainTab-container',
  DesignTab = 'MyAccountSettings-designTab',
  LayoutTab = 'MyAccountSettings-layoutTab',
  SettingsPanel = 'settings-tab-layout',
  SupportTab = 'MyAccountSettings-supportTab',
  TextTab = 'MyAccountSettings-textTab',
  DesignTabTextStyleListItem = 'MyAccountSettings-designTab-textStyle',
  DesignTabButtonStyleListItem = 'MyAccountSettings-designTab-buttonStyle',
  DesignTabInputStyleListItem = 'MyAccountSettings-designTab-inputStyle',
  DesignTabDividersListItem = 'MyAccountSettings-designTab-dividers',
  DesignTabBackgroundListItem = 'MyAccountSettings-designTab-background',
  SaveAccountButton = 'MyAccount-saveAccountButton',
  Toast = 'MyAccount-toast',
  SettingsTab = 'MyAccountSettings-settingsTab',
  MyAccountSettings = 'MyAccountSettings',
  MyAccountDropdownFieldText = 'dropdown-base-text',
  Avatar = 'MyAccount-avatar',
  DisplayInfoSection = 'MyAccount-displayInfoSection',

  LoginInfoSection = 'MyAccount-loginInfoSection',
  LoginInfoSectionChangeEmail = 'MyAccount-loginInfoSection-changeEmail',
  LoginInfoSectionChangePassword = 'MyAccount-loginInfoSection-changePassword',

  LoginInfoDialogPrimaryButton = 'LoginInfoDialog-primaryButton',
  LoginInfoDialogSecondaryButton = 'LoginInfoDialog-secondaryButton',
  LoginInfoDialogLoader = 'LoginInfoDialog-loader',

  ChangePasswordModal = 'MyAccount-ChangePassModal',
  ChangePasswordCurrentInput = 'MyAccount-ChangePassModal-currentInput',
  ChangePasswordForgotPassword = 'MyAccount-ChangePassModal-forgotPassword',
  ChangePasswordNewInput = 'MyAccount-ChangePassModal-newInput',
  ChangePasswordConfirmInput = 'MyAccount-ChangePassModal-confirmInput',

  ChangeEmailModal = 'MyAccount-ChangeEmailModal',
  ChangeEmailPasswordInput = 'MyAccount-ChangeEmailModal-passInput',
  ChangeEmailForgotPassword = 'MyAccount-ChangeEmailModal-forgotPassword',
  ChangeEmailNewInput = 'MyAccount-ChangeEmailModal-newInput',
  ChangeEmailConfirmInput = 'MyAccount-ChangeEmailModal-confirmInput',
}
