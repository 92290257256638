import React, { FC, useState, useCallback, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { TextField, TextFieldProps } from 'wix-ui-tpa/cssVars';

import { classes } from './LoginInfoTextField.st.css';

export interface LoginInfoTextFieldProps
  extends Omit<
    TextFieldProps,
    | 'value'
    | 'newErrorMessage'
    | 'className'
    | 'errorMessage'
    | 'error'
    | 'onChange'
  > {
  name: string;
  bottom?: React.ReactNode;
}

export const LoginInfoTextField: FC<LoginInfoTextFieldProps> = ({
  name,
  onBlur,
  bottom,
  ...props
}) => {
  const [isChanged, setChanged] = useState(false);
  const { setFieldValue, errors, touched } =
    useFormikContext<Record<string, any>>();

  const setBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setChanged(false);
      onBlur?.(event);
    },
    [onBlur],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChanged(true);
      setFieldValue(name, event.target.value);
    },
    [name],
  );

  const errorMessage = errors[name] as string;
  const showError = Boolean(!isChanged && touched[name] && errorMessage);

  return (
    <div className={classes.root}>
      <TextField
        {...props}
        name={name}
        onChange={onChange}
        onBlur={setBlur}
        error={showError}
        errorMessage={errorMessage}
        newErrorMessage
        className={classes.monochrome}
      />
      {bottom}
    </div>
  );
};
